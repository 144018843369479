import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import PickShipImg from '../../../images/pick_ship.jpg'

const paginaServizio = {
  titolo: 'Pick ship',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: PickShipImg,
      paragrafi: [
        {
          id: 1,
          testo:
            "Grazie a questo servizio puoi organizzare il ritiro di un pacco presso qualsiasi indirizzo - ad esempio un tuo fornitore - e richiedere la consegna presso domicili diversi dal tuo, in Italia o all'estero.",
        },
        {
          id: 2,
          testo:
            'Contatta la tua Sede di Competenza per verificare tutte le opportunità che ti offriamo!',
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo: 'Ottimizza il flusso delle tue spedizioni',
        },
        {
          id: 2,
          testo: 'Tieni sempre monitorato lo stato della consegna',
        },
        {
          id: 3,
          testo: 'Riduci i costi di trasporto, lavorazione e immagazzinaggio',
        },
        {
          id: 4,
          testo:
            'Gestisci facilmente la prenotazione tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
        {
          id: 3,
          nome: 'Parcel',
          linkUrl: '/servizi/parcel',
        },
      ],
      servizi: null,
    },
  ],
}

const PickShip = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default PickShip
